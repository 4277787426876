/*!
 * Start Bootstrap - Scrolling Nav (https://startbootstrap.com/template-overviews/scrolling-nav)
 * Copyright 2013-2017 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-scrolling-nav/blob/master/LICENSE)
 */

@import url('https://fonts.googleapis.com/css?family=Julius+Sans+One|Open+Sans');

:root {
  --clr-accent: #fbf5cc;
  --clr-brown-dark: #4d381f;
  --clr-brown-light: #7a6a5d;
  --clr-brown-dark: #7a6a5d;
  --clr-text: rgb(33, 37, 41);
  --clr-text-light: #f8f9fa;
}
/*Global*/

body {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button:focus,
.form-control:focus {
  outline: 2px solid #a5a7b4;
}

input:focus {
  outline: 2px solid #a5a7b4;
}

section {
  padding: 100px 0;
}

.bg-custom-lightbrown {
  background-color: var(--clr-brown-light);
}

.bg-custom-brown {
  background: var(--clr-brown-dark);
}

.bg-custom-lightblue {
  background-color: #a5a7b4;
}

.weneedmargin {
  margin-bottom: 50px;
}

.element-shadow {
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}

.content-link,
.content-link--light {
  text-decoration: none;
  background-position: 100% calc(100%);
  background-size: 100% 0%;
  background-repeat: no-repeat;
  transition: background-size .5s, color .5s;
}
.content-link {
  color: currentColor;
  border-bottom: 2px solid var(--clr-brown-dark);
  background-image: linear-gradient(var(--clr-brown-dark), var(--clr-brown-dark));
}

.content-link--light,
.content-link:visited {
  color: var(--clr-text-light);
  border-bottom: 2px solid var(--clr-text-light);
  background-image: linear-gradient(var(--clr-text-light), var(--clr-text-light));
}

.content-link:focus,
.content-link:hover,
.content-link:active,
.content-link:visited,
.content-link--light:focus,
.content-link--light:hover,
.content-link--light:active,
.content-link--light:visited {
  text-decoration: none;
  background-size: 100% 100%;
  transition: background-size .5s, color .5s;
}

.content-link:focus,
.content-link:hover,
.content-link:active {
  color: var(--clr-text-light);
}

.content-link--light:focus,
.content-link--light:hover,
.content-link--light:active {
  color: var(--clr-brown-dark);
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.9;
}

/*Navigation*/

nav {
  border-bottom: #a5a7b4 3px solid;
}

.navbar-brand {
  padding: 0px;
  margin: 0px;
}

.nav-link {
  color: #f8f9fa !important;
  font-size: 1.2rem;
  display: inline-block;
  transition: color 0.4s ease-in;
}

.active {
  /*color: var(--clr-accent) !important;*/
  border-bottom: solid 2px var(--clr-text-light);
  transition: color 0.4s ease-in;
}

.logo {
  background-color: black;
  background-image: url('../img/logo.png');
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  border-radius: 50px;
  display: inline-block;
  height: 80px;
  width: 80px;
  padding: 0;
  margin: 0;
}

/*Header*/

header {
  padding: 154px 0 100px;
}

header h1 {
  font-weight: 800;
}
header p {
  font-weight: 600;
}

#showcase {
  min-height: 650px;
  background-repeat: no-repeat;
  background-size: 100%;
  color: #21261f;
}

.jumbotron {
  background-image: url('../img/showcase.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.no-webp {
  .hero-image {
    background-image: url('../img/showcase.jpg');
  }
}

.webp {
  .hero-image {
    background-image: url('../img/showcase.webp');
  }
}

.hero-image {

  /* Set a specific height */
  height: 50%;

  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.no-webp {
  #parallax {
    background-image: url('../img/wandern.jpg');
  }
}

.webp {
  #parallax {
    background-image: url('../img/wandern.webp');
  }
}

#parallax {
  background-attachment: fixed;
  background-color: var(--clr-brown-dark);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  height: 60vh;
  z-index: -3;
}

/* Mitarbeiter Pics */

.img-container {
  width: auto;
  padding: 5% 10%;
}

.ratio {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  height: 0;
  padding-bottom: 100%;
  position: relative;
  width: 100%;
}
.img-circle {
  border-radius: 50%;
  border: solid;
  border-width: 1px;
  border-color: #fff;
}
.img-responsive {
  display: block;
  height: auto;
  max-width: 100%;
}

.img-text {
  padding-top: 8px;
}

.no-webp,
.no-js {
  #petra {
    background-image: url('../img/petra.jpg');
  }
  #prinzi {
    background-image: url('../img/prinzi.jpg');
  }
  #rio {
    background-image: url('../img/rio.jpg');
  }
  #lilli {
    background-image: url('../img/lilli.jpg');
  }
  #symphi {
    background-image: url('../img/symphi.jpg');
  }
}

.webp {
  #petra{
    background-image: url('../img/petra.webp');
  }
  #prinzi {
    background-image: url('../img/prinzi.webp');
  }
  #rio {
    background-image: url('../img/rio.webp');
  }
  #lilli {
    background-image: url('../img/lilli.webp');
  }
  #symphi {
    background-image: url('../img/symphi.webp');
  }
}



/*Contact*/
.custom-btn {
  background-color: #c3af97;
  color: black;
}

/*Anfahrt*/

#map {
  margin: 0px;
  padding: 0px;
  background-color: #a5a7b4;
}

#mapid {
  height: 72vh;
  width: 100%;
  border-radius: 4px;
}

.footer  {
  border-top: 3px solid var(--clr-brown-dark);
  & > div {
      text-transform: uppercase;

  }
  .footer__links {
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      margin-top: 8px;
      text-transform: capitalize;
    }
  }



}

/*Media Queries*/

@media only screen and (max-width: 1000px) {
  #parallax {
    height: 40vh;
  }
}
